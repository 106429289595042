$gray-50: #F5F7FA;  // Lightest grey
$gray-100: #EEF1F5; // Light grey
$gray-200: #DBE1E6;
$gray-300: #D4DDEA; // Grey
$gray-400: #BAC2CB;
$gray-500: #657590; // Dark grey
$gray-600: #636F7A;
$gray-700: #475569;
$gray-800: #1e293b;
$gray-900: #17263D; // Darkest grey
$white: #fff;
$black: $gray-900;

$light: $gray-50;
$secondary: $gray-500;

$font-size-base: 0.875rem;

$border-color: $gray-300;
$border-radius: .375rem !default;

$box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;

$body-bg: $gray-50;
$body-emphasis-color: $black;
$secondary-bg-subtle: $gray-100;

$table-bg: $white;
$table-cell-padding-x: 1rem;
$table-cell-vertical-align: middle;
$table-hover-bg: $gray-100;
$table-border-color: $gray-100;

$modal-content-bg: $white;
$modal-header-border-width: 0;
$modal-footer-bg: $gray-50;

$card-bg: $white;
$card-title-color: $secondary;
$card-border-color: $gray-300;
$card-cap-bg: $gray-100;
$card-inner-border-radius: $border-radius;

$link-decoration: none;
$link-color: $secondary;

$list-group-border-color: $gray-300;

$input-bg: $white;
$input-btn-padding-y: 0.45rem;

$pagination-margin-start: 0.375rem;
$pagination-bg: $white;
$pagination-color: body-color;
$pagination-border-color: $border-color;
$pagination-disabled-bg: $white;
$pagination-disabled-color: $gray-400;
$pagination-disabled-border-color: rgba($pagination-border-color, .65);
